<template>
  <div>
    <div class="hodo-el flex flex-wrap items-center space-x-3">
      <div class="flex items-center flex-1 space-x-3">
        <form @submit.prevent="enterSearch" class="w-full">
          <el-input
            class="w-full"
            :placeholder="placeholderSearch"
            prefix-icon="el-icon-search"
            v-model="search"
            @keyup.enter="enterSearch"
            @input="debounceSearch"
            clearable
          ></el-input>
        </form>
        <el-select
          v-if="filters && filters.length"
          v-model="selectedFilter"
          :placeholder="$t('Lọc trạng thái')"
          default-first-option
          @change="handleSelectFilter"
        >
          <el-option
            v-for="item in filters"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="ml-auto" v-if="!!textButton">
        <el-button
          v-if="!!textButton"
          type="primary"
          :disabled="btnIsDisabled"
          @click="handleEvent"
        >
          <span>{{ textButton || "Button" }}</span>
          <i v-if="iconButton" class="ml-1" :class="iconButton"></i>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConferenceHeader',
  props: {
    inputSearch: String,
    placeholderSearch: String,
    filterStatus: Object,
    textButton: String,
    iconButton: String,
    btnIsDisabled: Boolean
  },
  data () {
    return {
      search: this.inputSearch || '',
      filters: this.filterStatus?.options || [],
      selectedFilter: this.filterStatus?.value || 'all',
      debounce: null,
      typing: false
    }
  },
  methods: {
    debounceSearch () {
      this.typing = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.typing = false
        this.$emit('setSearchInput', this.search)
      }, 600)
    },
    enterSearch () {
      if (!this.search.length) return
      this.$emit('setSearchInput', this.search)
    },
    handleSelectFilter (value) {
      this.selectedFilter = value
      this.$emit('handleSelectFilter', value)
    },
    handleEvent () {
      this.$emit('handleEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .hodo-el {
    .el-input__inner {
      background: white !important;
      border: 1px solid #dee2e6 !important;
    }
  }
}
</style>

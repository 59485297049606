<template>
  <div>
    <el-table ref="ConferenceMultipleTable" :data="tableData" class="w-full" @select-all="handleSelectAll" @row-click="handleViewDetail" row-class-name="cursor-pointer">
      <!-- TODO: Select records -->
      <!-- <el-table-column type="selection" width="50" :selectable="customSelectable">
        <template slot-scope="scope">
          <div @click.stop>
            <el-checkbox
              v-if="scope.row.status === 1"
              v-model="scope.row.selected"
              @change="handleRecordSelect(scope.row)"
            ></el-checkbox>
          </div>
        </template>
        <template slot="header">
          <el-checkbox v-model="selectAllCheckbox" @change="handleSelectAllHeader"></el-checkbox>
        </template>
      </el-table-column>-->
      <el-table-column property="name" :label="$t('Họ và tên')" show-overflow-tooltip></el-table-column>
      <el-table-column property="email" :label="$t('Email')" show-overflow-tooltip></el-table-column>
      <el-table-column property="job_title" :label="$t('Chức vụ')" show-overflow-tooltip></el-table-column>
      <el-table-column property="workplace" :label="$t('Nơi làm việc')" show-overflow-tooltip></el-table-column>
      <el-table-column property="status" :label="$t('Trạng thái')">
        <template slot-scope="scope">
          <div :set="[(s = getStatus(scope.row.status))]">
            <i :class="`${s.color} ${s.icon}`" />
            <span class="ml-2" :class="s.color">{{ s.title }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column property="status" :label="$t('Thanh toán')">
        <template #default="scope">
          <div :set="[(s = getPaymentStatus(scope.row))]">
            <i :class="`${s.color} ${s.icon}`" />
            <span class="ml-2" :class="s.color">{{ s.title }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Xét duyệt" label-class-name="text-center" width="130">
        <template slot-scope="scope" v-if="scope.row.status === 1">
          <div class="text-center" @click.stop>
            <el-tooltip effect="dark" :content="$t('Chấp nhận')" placement="top">
              <el-button @click.stop="handleAccept(scope.row)" type="success" size="mini" icon="el-icon-check" circle plain></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" :content="$t('Từ chối')" placement="top">
              <el-button type="danger" size="mini" icon="el-icon-close" circle plain @click.stop="handleReject(scope.row)"></el-button>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('Đặt lại mật khẩu')" label-class-name="text-center" width="130">
        <template slot-scope="scope" v-if="scope.row.status === 2">
          <div class="text-center" @click.stop>
            <el-tooltip effect="dark" :content="$t('Đặt lại mật khẩu')" placement="top">
              <el-button @click.stop="showModalResetPassword(scope.row, true)" size="mini" plain type="primary">{{ $t("Đặt lại MK") }}</el-button>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div v-if="pagination && pagination.total > 1">
      <div class="flex mt-3 flex-col md:flex-row justify-between items-center gap-2">
        <div class="fs-16">Hiển thị đến {{ countItems }} trên {{ pagination.total }} mục</div>
        <el-pagination background layout="prev, pager, next" :pager-count="5" :page-size="pagination.page_size" :total="pagination.total" class="px-0" @next-click="handleChangePage" @prev-click="handleChangePage" @current-change="handleChangePage"></el-pagination>
      </div>
    </div>
    <ModalEditGuest id="ModalEditGuest" v-show="isShowEditModal" :isUpdate="true" @refresh="$emit('refresh')" :guestInfo="guestSelected" />
    <ModalResetPassword id="ModalResetPassword" v-show="isShowModalResetPassword" :guestInfo="guestSelected" />
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { CONFERENCE_PAYMENT } from '../../utils/constants'
import ModalEditGuest from './Modal/ModalEditGuest.vue'
import ModalResetPassword from './Modal/ModalResetPassword.vue'

export default {
  name: 'ConferenceGuestTable',
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      page: 1,
      multipleSelection: [],
      isAllSelected: false,
      selectAllCheckbox: false,
      guestSelected: {},
      isShowEditModal: false,
      isShowModalResetPassword: false
    }
  },
  computed: {
    countItems () {
      return this.page * this.pagination.page_size > this.pagination.total ? this.pagination.total : this.page * this.pagination.page_size
    }
  },
  watch: {
    isAllSelected (newValue) {
      console.log(newValue)
      this.selectAllCheckbox = newValue
      this.handleSelectAllHeader()
    }
  },
  methods: {
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.ConferenceMultipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.ConferenceMultipleTable.clearSelection()
      }
    },
    getStatus (value) {
      switch (value) {
        case 0:
          return {
            color: 'text-red-500',
            icon: 'el-icon-error',
            title: this.$t('Không xác định')
          }
        case 1:
          return {
            color: 'text-yellow-500',
            icon: 'el-icon-loading',
            title: this.$t('Chờ duyệt')
          }
        case 2:
          return {
            color: 'text-green-500',
            icon: 'el-icon-success',
            title: this.$t('Đã duyệt')
          }
        case 3:
          return {
            color: 'text-red-500',
            icon: 'el-icon-error',
            title: this.$t('Từ chối')
          }
        default:
          return {
            color: 'text-red-500',
            icon: 'el-icon-error',
            title: this.$t('Không xác định')
          }
      }
    },
    handleRowClick (value) {
      console.log(value)
    },
    handleClick (value) {
      console.log(value)
    },
    handleChangePage: debounce(function (newPage) {
      this.page = newPage
      this.$emit('handleChangePage', newPage)
    }, 500),
    async handleReject (record) {
      try {
        this.$prompt(
          `
            <p>Từ chối khách mời: <strong>${record.name}</strong></p>
            <p>Lý do từ chối <span class="text-red-500">*</span></p>
          `,
          'Bạn chắc chắn muốn từ chối khách mời?',
          {
            confirmButtonText: this.$t('Đồng ý'),
            cancelButtonText: this.$t('Hủy bỏ'),
            inputValidator: true,
            inputPattern: /^(?!\s*$)[a-zA-Z0-9_.+\s'\--￿]*$/,
            inputErrorMessage: this.$t('Lý do từ chối không được để trống'),
            dangerouslyUseHTMLString: true
          }
        )
          .then(async ({ value }) => {
            const { id } = record
            const data = {
              reason: value
            }
            await this.$rf.getRequest('ConferenceRequest').rejectConferenceRegister(id, data)
            this.$message({
              type: 'success',
              message: this.$t('Hành động từ chối đã thành công. Lời xin lỗi và thông báo đã được gửi tới email khách mời.')
            })
            this.$emit('refresh')
          })
          .catch(() => {})
      } catch (error) {
        console.log(error)
      }
    },
    customSelectable (record) {
      return record.status === 1
    },
    handleRecordSelect () {
      const selectedRecords = this.tableData.filter(r => r.selected && r.status === 1)
      this.isAllSelected = selectedRecords.length === this.tableData.filter(r => r.status === 1).length
      this.$emit('handleSelectGuests', selectedRecords)
    },
    handleSelectAll (selection) {
      if (selection.length === 0) {
        this.tableData.forEach(r => {
          if (r.status === 1) {
            r.selected = false
          }
        })
      } else {
        selection.forEach(r => {
          if (r.status === 1) {
            r.selected = true
          }
        })
      }
      this.isAllSelected = selection.length > 0
    },
    handleSelectAllHeader () {
      if (this.selectAllCheckbox) {
        this.toggleSelection(this.tableData.filter(r => r.status === 1))
      } else {
        this.toggleSelection()
      }
    },
    async handleAccept (data) {
      if (data?.org_service_trans?.payment_status === CONFERENCE_PAYMENT.unPaid) {
        this.$message({
          type: 'warning',
          message: this.$t(`Khách mời ${data.name} chưa thanh toán, bạn không thể xét duyệt vào hội thảo!`)
        })
        return
      }
      let r = await this.$confirm(`Hãy xác nhận bạn muốn duyệt <strong>${data.name}</strong> vào hội thảo không?`, { dangerouslyUseHTMLString: true })
      if (!r) return
      try {
        await this.$rf.getRequest('DoctorRequest').approveConferenceGuest(data.id)
        this.$message({
          type: 'success',
          message: this.$t(`Thêm thành công khách mời ${data.name} vào hội thảo`)
        })
        this.$emit('refresh')
      } catch (error) {
        this.$message({
          type: 'error',
          message: this.$t('Có lỗi xảy ra khi duyệt khách mời')
        })
        console.log(error)
      }
    },
    getPaymentStatus (data) {
      const isHasUnPayment = [
        CONFERENCE_PAYMENT.unPaid
        // CONFERENCE_PAYMENT.paid,
      ].some(el => data?.org_service_trans.map(item => item?.payment_status).includes(el))
      if (isHasUnPayment) {
        return {
          color: 'text-yellow-500',
          icon: 'el-icon-warning',
          title: this.$t('Chưa thanh toán')
        }
      } else {
        return {
          color: 'text-green-500',
          icon: 'el-icon-success',
          title: this.$t('Đã thanh toán')
        }
      }
    },
    handleViewDetail (data) {
      this.guestSelected = data
      this.showModalEditGuest(true)
    },
    showModalEditGuest (show) {
      this.isShowEditModal = show
      window.$('#ModalEditGuest').modal(show ? 'show' : 'hide')
    },
    showModalResetPassword (row, show) {
      this.guestSelected = row
      this.isShowModalResetPassword = show
      window.$('#ModalResetPassword').modal(show ? 'show' : 'hide')
    }
  },
  components: { ModalEditGuest, ModalResetPassword }
}
</script>

<style>
</style>

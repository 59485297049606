<template>
  <div>
    <el-table
      ref="ConferenceMultipleTable"
      :data="tableData"
      class="w-full cursor-pointer"
      :cell-class-name="'text-center'"
      :header-cell-class-name="'text-center'"
      @row-click="handleViewDetail"
    >
      <!-- <el-table-column type="selection" width="55"></el-table-column> -->
      <!-- <el-table-column label="Date" width="120">
        <template slot-scope="scope">{{ scope.row.date }}</template>
      </el-table-column>-->
      <el-table-column property="title" label="Tên phiên" show-overflow-tooltip></el-table-column>
      <el-table-column property="abstract" label="Tóm tắt nội dung" show-overflow-tooltip></el-table-column>
      <el-table-column
        property="conference_registrant.name"
        label="Diễn giả"
        width="150"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div class="d-flex flex-col items-start">
            <p class="m-0" style="font-size: 16px;">{{ scope.row.conference_registrant.name }}</p>
            <p class="m-0">{{ scope.row.conference_registrant.email }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        property="circle_schedule.circle_info.name"
        label="Phòng"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="Thời lượng" width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.duration ? scope.row.duration + " phút" : "" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Thời gian bắt đầu" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ formatDateTime(scope.row.schedule_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column property="keywords" label="Từ khóa" show-overflow-tooltip></el-table-column>
      <!-- <template slot-scope="scope">
        <div :set="[s = getStatus(scope.row.status)]">
          <i :class="`${s.color} ${s.icon}`" />
          <span class="ml-2">{{ s.title }}</span>
        </div>
      </template>-->
      <el-table-column label width="100">
        <template slot-scope="scope">
          <i class="el-icon-more cursor-pointer" @click.stop="toggleActionDropdown(scope.row)" />
          <div
            ref="dropdownWrapper"
            v-show="scope.row.isShowActionDropdown"
            class="action-dropdown flex-center flex-col position-absolute cursor-pointer w-100"
          >
            <div class="dropdown-option" @click.stop="handleEdit(scope.row)">Chỉnh sửa</div>
            <div class="dropdown-option" @click.stop="handleDuplicate(scope.row)">Nhân bản</div>
            <div class="dropdown-option" @click.stop="handleDelete(scope.row)">Xóa</div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div v-if="pagination && pagination.total > 1">
      <div class="flex mt-3 flex-col md:flex-row justify-between items-center gap-2">
        <div class="fs-16">Hiển thị đến {{ countItems }} trên {{ pagination.total }} mục</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :pager-count="5"
          :page-size="pagination.page_size"
          :total="pagination.total"
          class="px-0"
          @next-click="handleChangePage"
          @prev-click="handleChangePage"
          @current-change="handleChangePage"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'ReportTable',
  data () {
    return {
      tableData: [],
      pagination: {
        page_num: 1,
        page_size: 10,
        total: 0,
        total_page: 0
      }
    }
  },
  computed: {
    countItems () {
      return this.pagination.page_num * this.pagination.page_size >
        this.pagination.total
        ? this.pagination.total
        : this.pagination.page_num * this.pagination.page_size
    }
  },
  methods: {
    async getListGuests (new_params) {
      if (!this.$route.params.conf_id) return

      try {
        const params = {
          conference_id: Number(this.$route.params.conf_id),
          sort_by: 'schedule_time',
          order: 'asc',
          page_size: 10,
          ...new_params
        }
        const res = await this.$rf
          .getRequest('ConferenceRequest')
          .getListConferenceReport(params)
        if (!res.data) return
        this.tableData = [
          ...res.data?.data.map(item => {
            return { ...item, isShowActionDropdown: false }
          })
        ]
        this.pagination = { ...res.data?.page }
      } catch (err) {
        console.log(err)
      }
    },
    handleEdit (row) {
      console.log('Edit')
    },
    handleDuplicate (row) {
      console.log('Duplicate')
    },
    handleDelete (row) {
      console.log('Delete')
    },
    toggleActionDropdown (row) {
      const showing = this.tableData.find(
        item => item.isShowActionDropdown === true
      )
      if (showing) this.$set(showing, 'isShowActionDropdown', false)
      row.isShowActionDropdown = true
    },
    handleClickOutside (event) {
      if (
        this.$refs.dropdownWrapper &&
        !this.$refs.dropdownWrapper.contains(event.target)
      ) {
        this.tableData.forEach(row => {
          row.isShowActionDropdown = false
        })
      }
    },
    formatDateTime (date) {
      return window.moment(date).format('HH:mm DD/MM/YYYY') || ''
    },
    handleChangePage: debounce(function (newPage) {
      this.$emit('changePage', newPage)
    }, 500),
    handleViewDetail (data) {
      // this.$router.push({
      //   name: 'ConferenceReportDetail',
      //   params: { id: data.id }
      // })
      this.$emit('handleViewDetail', data)
    }
  },
  async created () {
    await this.getListGuests()
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<style lang="scss" scoped>
.action-dropdown {
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  border: 1px solid rgb(160, 158, 158);
  border-radius: 5px;
  background-color: white;
  padding: 2px 0;
  .dropdown-option {
    padding: 5px 10px;
    transition: background-color 0.5s;
    &:hover {
      background-color: rgb(216, 213, 213);
    }
  }
}
.el-table {
  overflow: visible;
  ::v-deep .el-table__body-wrapper {
    overflow: visible;
  }
}
</style>

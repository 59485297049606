<template>
  <CustomModal
    :showModal="isShow"
    @hideModal="$emit('onClose')"
    :title="$t(isUpdate?'Thông tin Báo cáo viên':'Thêm mới Báo cáo viên')"
    titleClass="text-3xl"
  >
    <div v-loading="isLoading">
      <div class="w-full">
        <div class="cs-img flex flex-col items-center gap-2 mb-4 mx-auto">
          <img v-if="imgUrl" :src="imgUrl" @click="handleTriggerImg" />
          <img v-else src="../../../../public/assets/images/no-avt.png" @click="handleTriggerImg" />

          <button
            v-if="!isUpdate"
            @click="handleTriggerImg"
            class="flex w-24 items-center justify-center border-0 focus:outline-none bg-blue-700 text-white rounded-md h-9 px-3 py-2"
          >{{ $t(imgUrl?'Đổi ảnh':'Thêm ảnh') }}</button>
          <input
            ref="uploadFile"
            type="file"
            class="hidden"
            accept="image/*"
            @change="handleChangeImage"
          />
        </div>
      </div>

      <div class="txt-grey-900">
        <div class="row">
          <div :class="isUpdate?'col-md-4':'col-md-6'">
            <div class="flex flex-col gap-2">
              <label class="fs-14 fw-500 required">{{$t('Email') }}</label>
              <input
                :disabled="isUpdate"
                v-model="form.email"
                type="text"
                class="form--input fs-14 fw-500 h-10"
                :class="isUpdate&&'cursor-not-allowed'"
                :placeholder="$t('Nhập email')"
              />
            </div>
          </div>
          <div :class="isUpdate?'col-md-4':'col-md-6'">
            <div class="flex flex-col gap-2">
              <label class="fs-14 fw-500">{{ $t('Số điện thoại') }}</label>
              <input
                v-model="form.phone"
                type="text"
                class="form--input fs-14 fw-500 h-10"
                :placeholder="$t('Nhập số điện thoại')"
              />
            </div>
          </div>
          <div v-if="isUpdate" class="col-md-4">
            <div class="flex flex-col gap-2">
              <label class="fs-14 fw-500">{{ $t('Số lượng báo cáo') }}</label>
              <input
                :disabled="true"
                v-model="form.count_report"
                type="text"
                class="form--input fs-14 fw-500 h-10 cursor-not-allowed"
                :placeholder="$t('Nhập số lượng báo cáo')"
              />
            </div>
          </div>
        </div>
        <div v-for="(speaker,index) in form.multilang" :key="index" class="row mt-3">
          <div class="col-md-4">
            <div class="flex flex-col gap-2">
              <label
                class="fs-14 fw-500"
                :class="speaker.lang===1&&'required'"
              >{{ getLabelData(speaker.lang).name }}</label>
              <input
                v-model="speaker.name"
                type="text"
                class="form--input fs-14 fw-500 h-10"
                :placeholder="$t('Nhập họ và tên')"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="flex flex-col gap-2">
              <label
                class="fs-14 fw-500"
                :class="speaker.lang===1&&'required'"
              >{{ getLabelData(speaker.lang).job_title }}</label>
              <input
                v-model="speaker.job_title"
                type="text"
                class="form--input fs-14 fw-500 h-10"
                :placeholder="$t('Nhập chức vụ')"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="flex flex-col gap-2">
              <label
                class="fs-14 fw-500"
                :class="speaker.lang===1&&'required'"
              >{{ getLabelData(speaker.lang).workplace }}</label>
              <input
                v-model="speaker.workplace"
                type="text"
                class="form--input fs-14 fw-500 h-10"
                :placeholder="$t('Nhập nơi làm việc')"
              />
            </div>
          </div>
        </div>

        <div class="mt-6 text-sm font-medium flex justify-end">
          <button
            :disabled="isLoading"
            @click="handleSubmit"
            class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2"
          >
            <div>{{ $t(isUpdate?'Cập nhật':'Thêm mới') }}</div>
          </button>
          <button
            :disabled="isLoading"
            @click="showModalEditGuest(false)"
            class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2"
          >
            <div>{{ $t('Hủy') }}</div>
          </button>
        </div>
      </div>
    </div>
  </CustomModal>
</template>
  <script>
import { ButtonHoDo } from '@/components/Button'
import CustomModal from '@/components/CustomModal'

export default {
  name: 'ModalEditSpeaker',
  components: { ButtonHoDo, CustomModal },
  props: {
    speakerInfo: {
      type: Object,
      default: function () {
        return {
          name: '',
          email: '',
          job_title: '',
          workplace: '',
          count_report: '',
          phone: ''
        }
      }
    },
    isShow: Boolean,
    isUpdate: Boolean
  },
  data () {
    return {
      isLoading: false,
      form: {
        name: '',
        email: '',
        job_title: '',
        workplace: '',
        count_report: '',
        phone: '',
        imgFile: '',
        multilang: [
          {
            lang: 1,
            name: '',
            job_title: '',
            workplace: ''
          },
          {
            lang: 2,
            name: '',
            job_title: '',
            workplace: ''
          },
          {
            lang: 3,
            name: '',
            job_title: '',
            workplace: ''
          }
        ]
      },
      imgUrl: ''
    }
  },
  computed: {},
  watch: {
    speakerInfo: {
      deep: true,
      handler () {
        this.handleMapData()
      }
    }
  },
  created () {
    this.handleMapData()
  },
  methods: {
    showModalEditGuest (show) {
      this.$emit('onClose')
      // window.$('#ModalEditSpeaker').modal(show ? 'show' : 'hide')
    },
    handleMapData () {
      this.form = {
        ...this.form,
        name: this.speakerInfo.name,
        email: this.speakerInfo.email,
        job_title: this.speakerInfo.job_title,
        workplace: this.speakerInfo.workplace,
        count_report: this.speakerInfo.count_report,
        phone: this.speakerInfo.phone,
        multilang:
          this.speakerInfo?.user_infor?.speaker_multi_language ||
          this.form.multilang
      }
    },
    handleSubmit () {
      if (this.speakerInfo?.id) {
        this.handleUpdateSpeakerInfo()
      } else {
        this.handleCreateSpeaker()
      }
    },
    async handleUpdateSpeakerInfo () {
      if (!this.speakerInfo?.id) return

      try {
        this.isLoading = true
        const id = this.speakerInfo.id

        const params = {
          job_title: this.form.job_title,
          workplace: this.form.workplace,
          phone: this.form.phone,
          name: this.form.name,
          multilang: this.form.multilang
        }

        await this.$rf
          .getRequest('ConferenceRequest')
          .updateConferenceMemberInfo(id, params)

        this.showModalEditGuest(false)
        this.$emit('refresh')

        this.$message({
          type: 'success',
          message: this.$t('Cập nhật thông tin báo cáo viên thành công')
        })
      } catch (error) {
        console.log(error)

        this.$message({
          type: 'error',
          message: this.$t('Có lỗi xảy ra khi cập nhật thông tin báo cáo viên')
        })
      } finally {
        this.isLoading = false
      }
    },
    handleChangeImage (e) {
      if (!e.target.files?.[0]) return
      const file = e.target.files[0]
      this.form.imgFile = file

      const url = window.URL.createObjectURL(file)
      this.imgUrl = url
    },
    handleTriggerImg () {
      this.$refs.uploadFile.click()
    },
    getLabelData (lang) {
      switch (lang) {
        case 1:
          return {
            name: this.$t('Báo cáo viên (Tiếng Việt)'),
            job_title: this.$t('Chức vụ (Tiếng Việt)'),
            workplace: this.$t('Nơi làm việc (Tiếng Việt)')
          }

        case 2:
          return {
            name: this.$t('Báo cáo viên (Tiếng Anh)'),
            job_title: this.$t('Chức vụ (Tiếng Anh)'),
            workplace: this.$t('Nơi làm việc (Tiếng Anh)')
          }

        case 3:
          return {
            name: this.$t('Báo cáo viên (Tiếng Pháp)'),
            job_title: this.$t('Chức vụ (Tiếng Pháp)'),
            workplace: this.$t('Nơi làm việc (Tiếng Pháp)')
          }

        default:
          return {
            name: this.$t('Báo cáo viên (Tiếng Việt)'),
            job_title: this.$t('Chức vụ (Tiếng Việt)'),
            workplace: this.$t('Nơi làm việc (Tiếng Việt)')
          }
      }
    },
    async handleCreateSpeaker () {
      if (!this.$route.params.conf_id) return

      try {
        const conference_id = Number(this.$route.params.conf_id)

        const params = {
          conference_id,
          email: this.form.email,
          phone: this.form.phone,
          multilang: this.form.multilang,
          registration_type: 2 // SPEAKER
        }

        const response = await this.$rf
          .getRequest('ConferenceRequest')
          .createSpeaker(params)

        if (response.data?.code === 2) {
          this.$message({
            type: 'error',
            message: this.$t(
              'Email đã được sử dụng, vui lòng nhập email khác để thêm mới báo cáo viên'
            )
          })
        } else {
          this.showModalEditGuest(false)
          this.$emit('refresh')
          this.$message({
            type: 'success',
            message: this.$t('Thêm mới báo cáo viên thành công')
          })
        }
      } catch (error) {
        console.log(error)
        this.$message({
          type: 'error',
          message: this.$t('Có lỗi xảy ra khi thêm mới báo cáo viên')
        })
      }
    }
  }
}
</script>
  <style lang="scss" scoped>
.cs-img {
  width: 200px;
  margin-left: auto;
  margin-right: auto;

  img {
    height: 200px;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
    border: 1px solid #20409b;
    width: 100%;
    max-width: unset;
  }
}

@media (min-width: 640px) {
  .cs-img {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>

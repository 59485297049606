<template>
  <div>
    <div class="modal fade" :id="id">
      <div
        v-loading="isLoading"
        class="modal-dialog modal-md shadow"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{
                $t("Đặt lại mật khẩu")
              }}
            </h5>
          </div>
          <div class="modal-body txt-grey-900">
            <div class="row">
              <div class="col-md-12">
                <div class="flex flex-col gap-2">
                  <label class="fs-14 fw-500 required">{{
                    $t("Mật khẩu mới")
                  }}</label>
                  <div class="flex">
                    <el-input
                      ref="reset-pwd"
                      type="text"
                      v-model="newPwd"
                      :show-password="!!newPwd"
                      :minlength="8"
                    >
                      <el-button slot="append" icon="el-icon-refresh" @click="generateNewPwd"></el-button>
                    </el-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-6 text-sm font-medium flex justify-end">
              <button
                :disabled="isLoading"
                @click="handleSubmit"
                class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2"
              >
                <div>{{ $t("Đặt lại MK") }}</div>
              </button>
              <button
                :disabled="isLoading"
                @click="showModalResetPwd(false)"
                class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2"
              >
                <div>{{ $t("Hủy") }}</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import { ButtonHoDo } from '@/components/Button'

export default {
  name: 'ModalResetPassword',
  components: { ButtonHoDo },
  props: {
    guestInfo: {
      type: Object,
      default: function () {
        return {
          name: '',
          email: '',
          job_title: '',
          workplace: '',
          phone: ''
        }
      }
    },
    id: String
  },
  data () {
    return {
      isLoading: false,
      newPwd: null
    }
  },
  computed: {},
  watch: {
    guestInfo: {
      deep: true,
      handler () {
        this.generateNewPwd()
      }
    }
  },
  mounted () {
    this.generateNewPwd()
  },
  methods: {
    generateNewPwd () {
      this.newPwd = Math.random() // Generate random number, eg: 0.123456
        .toString(36) // Convert  to base-36 : "0.4fzyo82mvyr"
        .slice(-8).toUpperCase()// Cut off last 8 characters : "yo82mvyr"
    },
    showModalResetPwd (show) {
      window.$(`#${this.id}`).modal(show ? 'show' : 'hide')
    },
    async handleResetPwd () {
      if (!this.guestInfo?.id) return

      try {
        this.isLoading = true
        const id = this.guestInfo.id
        const params = {
          email: this.guestInfo.email,
          password: this.newPwd
        }

        const response = await this.$rf
          .getRequest('ConferenceRequest')
          .resetPwd(id, params)

        this.showModalResetPwd(false)
        this.$emit('refresh')

        this.$message({
          type: 'success',
          message: this.$t('Cập nhật thông tin khách mời thành công')
        })

        console.log(response)
      } catch (error) {
        console.log(error)

        this.$message({
          type: 'error',
          message: this.$t('Có lỗi xảy ra khi cập nhật thông tin khách mời')
        })
      } finally {
        this.isLoading = false
      }
    },
    handleSubmit () {
      if (this.guestInfo?.id) {
        this.handleResetPwd()
      }
    }
  }
}
</script>
  
  <style scoped>
.cs-disabled {
  background-color: #cdcdcd74;
  color: #828282;
}
::v-deep .el-input__inner {
  border-radius: unset !important;
}
button:focus {
  outline: none;
}
</style>
  
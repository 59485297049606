<template>
  <div>
    <el-table
      ref="ConferenceMultipleTable"
      :data="tableData"
      class="w-full"
      @row-click="handleViewDetail"
      row-class-name="cursor-pointer"
    >
      <el-table-column :label="$t('Báo cáo viên')" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>
            <div>{{ getMultilangValue(scope.row.user_infor.speaker_multi_language).name }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column property="email" :label="$t('Email')" show-overflow-tooltip></el-table-column>
      <el-table-column property="position" :label="$t('Mô tả Báo cáo viên')" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-if="scope.row.langData">
            <div>{{ scope.row.langData.job_title }}</div>
            <div>{{ scope.row.langData.workplace }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        property="count_report"
        :label="$t('Số lượng báo cáo')"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>

    <div v-if="pagination && pagination.total > 1">
      <div class="flex mt-3 flex-col md:flex-row justify-between items-center gap-2">
        <div class="fs-16">Hiển thị đến {{ countItems }} trên {{ pagination.total }} mục</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :pager-count="5"
          :page-size="pagination.page_size"
          :total="pagination.total"
          class="px-0"
          @next-click="handleChangePage"
          @prev-click="handleChangePage"
          @current-change="handleChangePage"
        ></el-pagination>
      </div>
    </div>
    <ModalEditSpeaker
      :isUpdate="true"
      :isShow="isShowModal"
      @onClose="()=>{isShowModal=false}"
      @refresh="$emit('refresh')"
      :speakerInfo="speakerSelected"
    />
  </div>
</template>
  
  <script>
import { debounce } from 'lodash'
import ModalEditSpeaker from './Modal/ModalEditSpeaker.vue'
import { LANGUAGE_CODES_FOR_DISEASE } from '@/lang'

export default {
  name: 'ConferenceSpeakerTable',
  components: { ModalEditSpeaker },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      page: 1,
      multipleSelection: [],
      isAllSelected: false,
      selectAllCheckbox: false,
      speakerSelected: {},
      isShowModal: false
    }
  },
  computed: {
    countItems () {
      return this.page * this.pagination.page_size > this.pagination.total
        ? this.pagination.total
        : this.page * this.pagination.page_size
    },
    lang () {
      return LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()]
    }
  },
  watch: {
    isAllSelected (newValue) {
      this.selectAllCheckbox = newValue
      this.handleSelectAllHeader()
    }
  },
  methods: {
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.ConferenceMultipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.ConferenceMultipleTable.clearSelection()
      }
    },
    handleRowClick (value) {
      console.log(value)
    },
    handleClick (value) {
      console.log(value)
    },
    handleChangePage: debounce(function (newPage) {
      this.page = newPage
      this.$emit('handleChangePage', newPage)
    }, 500),
    customSelectable (record) {
      return record.status === 1
    },
    handleViewDetail (data) {
      this.speakerSelected = data
      // this.showModalEditGuest(true)
      this.isShowModal = true
    },
    showModalEditGuest (show) {
      window.$('#ModalEditSpeaker').modal(show ? 'show' : 'hide')
    },
    getMultilangValue (data) {
      return data?.find(item => (item?.Lang || item?.lang) === this.lang) || {}
    }
  }
}
</script>
  
  <style>
</style>
  
<template>
  <div>
    <el-dialog
      title="Tải lên"
      :visible="dialogVisible"
      width="40%"
      :before-close="handleClose"
      center
    >
      <div v-loading="loading">
        <div class="upload-section d-flex justify-center items-center flex-column">
          <el-upload
            action="#"
            ref="upload"
            :auto-upload="false"
            :multiple="false"
            :show-file-list="false"
            :on-change="handleFileUpload"
            accept=".xlsx, .xls"
            drag
          >
            <svg
              height="100px"
              width="100px"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 184.69 184.69"
              xml:space="preserve"
            >
              <g>
                <g>
                  <g>
                    <path
                      style="fill: #4c95eb"
                      d="M149.968,50.186c-8.017-14.308-23.796-22.515-40.717-19.813
                        C102.609,16.43,88.713,7.576,73.087,7.576c-22.117,0-40.112,17.994-40.112,40.115c0,0.913,0.036,1.854,0.118,2.834
                        C14.004,54.875,0,72.11,0,91.959c0,23.456,19.082,42.535,42.538,42.535h33.623v-7.025H42.538
                        c-19.583,0-35.509-15.929-35.509-35.509c0-17.526,13.084-32.621,30.442-35.105c0.931-0.132,1.768-0.633,2.326-1.392
                        c0.555-0.755,0.795-1.704,0.644-2.63c-0.297-1.904-0.447-3.582-0.447-5.139c0-18.249,14.852-33.094,33.094-33.094
                        c13.703,0,25.789,8.26,30.803,21.04c0.63,1.621,2.351,2.534,4.058,2.14c15.425-3.568,29.919,3.883,36.604,17.168
                        c0.508,1.027,1.503,1.736,2.641,1.897c17.368,2.473,30.481,17.569,30.481,35.112c0,19.58-15.937,35.509-35.52,35.509H97.391
                        v7.025h44.761c23.459,0,42.538-19.079,42.538-42.535C184.69,71.545,169.884,53.901,149.968,50.186z"
                    />
                  </g>
                  <g>
                    <path
                      style="fill: #4c95eb"
                      d="M108.586,90.201c1.406-1.403,1.406-3.672,0-5.075L88.541,65.078
                        c-0.701-0.698-1.614-1.045-2.534-1.045l-0.064,0.011c-0.018,0-0.036-0.011-0.054-0.011c-0.931,0-1.85,0.361-2.534,1.045
                        L63.31,85.127c-1.403,1.403-1.403,3.672,0,5.075c1.403,1.406,3.672,1.406,5.075,0L82.296,76.29v97.227
                        c0,1.99,1.603,3.597,3.593,3.597c1.979,0,3.59-1.607,3.59-3.597V76.165l14.033,14.036
                        C104.91,91.608,107.183,91.608,108.586,90.201z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </el-upload>
          <p class="description">Thêm file danh sách báo cáo viên</p>
          <p class="support">Định dạng hỗ trợ: .xlsx, .xls</p>
          <a
            href="https://api.hodo.app/api/go/static/uploads//1/import_conference_speaker_list_template.xlsx"
            target="_blank"
            type="primary"
            class="link"
          >Mẫu tài liệu danh sách báo cáo viên</a>
        </div>
        <el-upload
          class="upload-line"
          action="#"
          :auto-upload="false"
          :multiple="false"
          :show-file-list="false"
          :on-change="handleFileUpload"
          accept=".xlsx, .xls"
        >
          <div
            v-if="!file"
            class="upload-container d-flex items-center"
            slot="trigger"
            type="primary"
          >Chọn tệp...</div>
          <div
            v-else-if="file"
            class="file-uploaded d-flex items-center"
            slot="trigger"
            type="primary"
          >
            {{ file.name }}
            <svg
              @click.stop="removeFile"
              width="20"
              height="20"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="9.51855" cy="9.37081" r="8.60925" fill="#FFF3F3" />
              <path
                d="M13.2602 6.29855H12.1845C12.1701 6.29357 12.1552 6.29033 12.14 6.28884H10.9947V5.33895C10.9948 5.26757 10.9668 5.19903 10.9169 5.14805C10.867 5.09707 10.7991 5.06766 10.7278 5.06617H8.0191C7.94674 5.06617 7.87737 5.09487 7.82626 5.14604C7.77508 5.19721 7.74632 5.26659 7.74632 5.33895V6.28884H6.60683C6.59167 6.29033 6.57671 6.29356 6.56233 6.29855H5.48664C5.38915 6.29855 5.29911 6.3505 5.2504 6.43491C5.20169 6.51931 5.20169 6.62327 5.2504 6.70768C5.29911 6.79208 5.38915 6.8441 5.48664 6.8441H6.36302L7.07116 13.4317C7.07873 13.4987 7.11073 13.5606 7.16107 13.6056C7.21133 13.6505 7.27643 13.6754 7.34393 13.6754H11.4067C11.4735 13.6744 11.5377 13.6491 11.5872 13.6043C11.6367 13.5594 11.6681 13.4981 11.6756 13.4317L12.3837 6.85372H13.2601C13.3576 6.85372 13.4477 6.80177 13.4964 6.71736C13.5451 6.63295 13.5451 6.52893 13.4964 6.44459C13.4477 6.36018 13.3576 6.30817 13.2601 6.30817L13.2602 6.29855ZM8.29776 5.61172H10.4491V6.28884H8.29776V5.61172ZM11.1591 13.1259H7.58768L6.91057 6.85378H11.8362L11.1591 13.1259Z"
                fill="#E41D1D"
              />
              <path
                d="M9.37348 12.4876C9.44584 12.4876 9.51521 12.4588 9.56639 12.4077C9.61756 12.3566 9.64626 12.2872 9.64626 12.2148V7.76513C9.64626 7.66764 9.59431 7.57759 9.5099 7.52888C9.4255 7.48011 9.32147 7.48011 9.23706 7.52888C9.15266 7.5776 9.10071 7.66764 9.10071 7.76513V12.2148C9.10071 12.2872 9.1294 12.3566 9.18058 12.4077C9.23175 12.4588 9.30113 12.4876 9.37349 12.4876H9.37348Z"
                fill="#E41D1D"
              />
              <path
                d="M8.29984 12.4856H8.31144C8.38347 12.4826 8.45135 12.4512 8.50033 12.3983C8.54924 12.3453 8.57528 12.2752 8.57262 12.2031L8.40815 7.75346L8.40822 7.75339C8.40673 7.68052 8.37557 7.61133 8.32187 7.56197C8.26811 7.51268 8.19659 7.48748 8.12379 7.49221C8.05176 7.49525 7.98387 7.52667 7.93496 7.57959C7.88599 7.63252 7.86001 7.70267 7.8626 7.77471L8.02707 12.2244C8.03051 12.2946 8.06069 12.3608 8.11148 12.4093C8.1622 12.4579 8.22963 12.4852 8.29984 12.4856L8.29984 12.4856Z"
                fill="#E41D1D"
              />
              <path
                d="M10.4374 12.4856H10.4471C10.519 12.4872 10.5886 12.4605 10.6411 12.4114C10.6935 12.3621 10.7246 12.2943 10.7276 12.2225L10.8921 7.77277H10.892C10.8947 7.70073 10.8686 7.63058 10.8197 7.57765C10.7708 7.52473 10.7029 7.49331 10.6308 7.49027C10.5572 7.48269 10.4837 7.50646 10.4285 7.55582C10.3732 7.60512 10.3414 7.67546 10.3406 7.74958L10.1742 12.1993H10.1743C10.1706 12.2723 10.1964 12.3437 10.2459 12.3976C10.2953 12.4514 10.3644 12.4831 10.4374 12.4856L10.4374 12.4856Z"
                fill="#E41D1D"
              />
            </svg>
          </div>
        </el-upload>
      </div>
      <p v-if="message.error" class="error-message">{{ message.error }}</p>
      <el-button
        :disabled="loading"
        @click="sendFormattedDataToServer"
        type="primary"
        class="send-data"
      >Tải lên</el-button>
    </el-dialog>
  </div>
</template>
  <script>
import * as XLSX from 'xlsx'

export default {
  name: 'ModalImportSpeakers',
  components: {},
  data () {
    return {
      dialogVisible: false,
      loading: false,
      file: null,
      fileData: [],
      patientList: [],
      message: {
        success: '',
        error: ''
      }
    }
  },
  computed: {
    isDisableUploadBtn () {
      return !this.patientList.length && !this.file
    }
  },
  methods: {
    async handleFileUpload (file) {
      this.clearMessage()
      if (this.validateUpload(file)) {
        this.file = file
        await this.convertFileDateToArray()
      }
    },
    async convertFileDateToArray () {
      const reader = new FileReader()
      reader.onload = e => {
        /* Parse data */
        const bstr = e.target.result
        const wb = XLSX.read(bstr, { type: 'binary' })
        /* Get first worksheet */
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        /* Convert array of arrays */
        this.fileData = XLSX.utils.sheet_to_json(ws, { header: 1 })
        this.fileData = this.fileData.filter(row => !!row.length)
        this.formatArrayFromFileToSpeakerList()
      }
      await reader.readAsBinaryString(this.file.raw)
    },
    formatArrayFromFileToSpeakerList () {
      const columns = this.fileData[0]
      let patientList = this.fileData.slice(1)
      let patientListFormatted = []
      patientList.forEach((patient, i) => {
        let patientFormatted = {
          conference_id: Number(this.$route.params.conf_id),
          stt: i + 1
        } // TODO update conference id later
        columns.forEach((col, index) => {
          switch (col) {
            case 'ten_bcv':
              patientFormatted.name_vi = patient[index]
              break
            case 'ten_bcv_en':
              patientFormatted.name_en = patient[index]
              break
            case 'ten_bcv_fr':
              patientFormatted.name_fr = patient[index]
              break
            case 'email':
              patientFormatted.email = patient[index]
              break
            case 'noi_lam_viec':
              patientFormatted.workplace_vi = patient[index]
              break
            case 'noi_lam_viec_en':
              patientFormatted.workplace_en = patient[index]
              break
            case 'noi_lam_viec_fr':
              patientFormatted.workplace_fr = patient[index]
              break
            case 'chuc_vu':
              patientFormatted.job_title_vi = patient[index]
              break
            case 'chuc_vu_en':
              patientFormatted.job_title_en = patient[index]
              break
            case 'chuc_vu_fr':
              patientFormatted.job_title_fr = patient[index]
              break
            case 'chuyen_gia':
              patientFormatted.specialist_vi = patient[index]
              break
            case 'chuyen_gia_en':
              patientFormatted.specialist_en = patient[index]
              break
            case 'chuyen_gia_fr':
              patientFormatted.specialist_fr = patient[index]
              break
            case 'sdt':
              patientFormatted.phone = patient[index]
              break
            default:
              patientFormatted[col] = patient[index]
              break
          }
        })
        patientListFormatted.push(patientFormatted)
      })
      this.message.success = `Đọc ${patientListFormatted.length}/${patientList.length} dòng thành công. Nhấn tải lên để lưu lại.`
      this.$message.success(this.message.success)
      if (patientList.length - patientListFormatted.length) {
        this.message.error = `Đọc ${patientList.length -
          patientListFormatted.length} dòng thất bại do sai định dạng. Vui lòng kiểm tra lại.`
      }
      this.patientList = patientListFormatted
    },
    async sendFormattedDataToServer () {
      let params = {
        data: this.patientList
      }
      try {
        this.loading = true
        let result = await this.$rf
          .getRequest('ConferenceRequest')
          .importConferenceSpeakersFromExcelFile(params)
        this.$message.success(
          `Nhập dữ liệu ${result.data.number_success}/${this.patientList.length} bệnh nhân thành công.` +
            (result.data.number_duplicate?.length
              ? ` Trong đó đã tìm thấy ${
                result.data.number_duplicate.length
              } bản ghi bị trùng ở ${this.getErrorSpeakerIndex(
                result.data.number_duplicate
              )}.`
              : '')
        )
        if (result.data.number_error?.length) {
          this.$nextTick(() => {
            this.$message.error(
              `Lưu dữ liệu ${
                result.data.number_error.length
              } báo cáo viên thất bại. Vui lòng kiểm tra lại ở các bản ghi ${this.getErrorSpeakerIndex(
                result.data.number_error
              )}`
            )
          })
        }
      } catch (error) {
        console.log(error)
        this.$message.error('Có lỗi xảy ra, vui lòng thử lại.')
      } finally {
        this.loading = false
      }
      this.clearMessage()
      this.close()
      this.$emit('import')
    },
    getErrorSpeakerIndex (duplicate_speakers) {
      let message = ''
      duplicate_speakers.forEach((el, index) => {
        if (index === 0) message += el.name_item
        else message += `, ${el.name_item}`
      })
      return message
    },
    validateUpload (file) {
      const isXLSX = ['xlsx', 'xls'].includes(
        file.name.split('.')[file.name.split('.').length - 1]
      )
      const isLt2M = file.size / 1024 / 1024 < 20
      if (!isXLSX) {
        this.$message.error('Vui lòng chọn tệp đúng định dạng excel!')
      }
      if (!isLt2M) {
        this.$message.error(
          'Vui lòng chọn tệp có dung lượng không vượt quá 20MB!'
        )
      }
      return isXLSX && isLt2M
    },
    clearMessage () {
      this.message = {
        success: '',
        error: ''
      }
    },
    removeFile () {
      this.$refs.upload.clearFiles()
      this.clearMessage()
      this.file = null
      this.fileData = []
      this.patientList = []
    },
    close () {
      this.dialogVisible = false
    },
    openDialog () {
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
    }
  }
}
</script>
  <style lang="scss" scoped>
.upload-section {
  background-color: #eef6ff;
  border: dashed 2px #2774cf;
  margin: 0px 20px 20px 20px;
  padding-top: 20px;
  padding-bottom: 15px;
  .description {
    font-weight: 700;
    font-size: 18px;
    height: 26px;
    line-height: 26px;
    margin-top: 10px;
  }
  .support {
    font-weight: 400;
    font-size: 13px;
    height: 20px;
    line-height: 20px;
    color: #676767;
  }
  .link {
    color: #4c95eb;
    text-decoration: underline;
    cursor: pointer;
  }
  ::v-deep .el-upload-dragger {
    background-color: transparent;
    border: none;
    height: auto;
  }
}
.upload-line {
  margin: 0 20px 15px 20px;
  display: block;
  ::v-deep .el-upload {
    display: block;
    .upload-container {
      height: 40px;
      border: 1px solid #11af22;
      border-radius: 4px;
      padding: 5px 10px;
      justify-content: flex-start;
    }
  }
}
.file-uploaded {
  margin-bottom: 15px;
  height: 40px;
  border: 1px solid #11af22;
  border-radius: 4px;
  padding: 5px 10px;
  justify-content: flex-start;
  cursor: auto;
  position: relative;
  svg {
    z-index: 1;
    cursor: pointer;
    position: absolute;
    right: 0;
    transform: translate(-50%);
  }
}
.send-data {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: calc(100% - 40px);
  background: #476dd6;
  border-color: #476dd6;
}
::v-deep {
  .el-dialog__header {
    font-weight: 700;
    font-size: 24px;
    color: #0f0f0f;
  }
  .el-dialog__body {
    padding-top: 0px;
  }
  .select-cs {
    .el-input__inner {
      height: 32px !important;
    }
  }
}
.error-message {
  color: red;
  margin: 0px 20px 5px;
  word-break: break-word;
}
</style>

<template>
  <div>
    <div class="modal fade" :id="id">
      <div v-loading="isLoading" class="modal-dialog modal-lg shadow" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t(isUpdate ? "Cập nhật thông tin Khách mời" : "Thêm mới Khách mời") }}</h5>
          </div>
          <div class="modal-body txt-grey-900">
            <div class="row">
              <div class="col-md-6">
                <div class="flex flex-col gap-2">
                  <label class="fs-14 fw-500 required">{{ $t("Họ và tên") }}</label>
                  <input v-model="form.name" type="text" class="form--input fs-14 fw-500 h-10" :placeholder="$t('Nhập họ và tên')" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="flex flex-col gap-2">
                  <label class="fs-14 fw-500 required">{{ $t("Email") }}</label>
                  <input :disabled="isUpdate" v-model="form.email" type="text" class="form--input fs-14 fw-500 h-10" :class="isUpdate && 'cursor-not-allowed cs-disabled'" :placeholder="$t('Nhập email')" />
                </div>
              </div>
              <div class="col-md-6 mt-1">
                <div class="flex flex-col gap-2">
                  <label class="fs-14 fw-500">{{ $t("Số điện thoại") }}</label>
                  <input v-model="form.phone" type="text" class="form--input fs-14 fw-500 h-10" :placeholder="$t('Nhập số điện thoại')" />
                </div>
              </div>
              <div class="col-md-6 mt-1">
                <div class="flex flex-col gap-2">
                  <label class="fs-14 fw-500">{{ $t("Chức vụ") }}</label>
                  <input v-model="form.job_title" type="text" class="form--input fs-14 fw-500 h-10" :placeholder="$t('Nhập chức vụ')" />
                </div>
              </div>
              <div class="col-md-6 mt-1">
                <div class="flex flex-col gap-2">
                  <label class="fs-14 fw-500">{{ $t("Nơi làm việc") }}</label>
                  <input v-model="form.workplace" type="text" class="form--input fs-14 fw-500 h-10" :placeholder="$t('Nhập nơi làm việc')" />
                </div>
              </div>
              <div class="col-md-6 mt-1">
                <div class="flex flex-col gap-2">
                  <label class="fs-14 fw-500 required">{{ $t("Đặt vé") }}</label>
                  <el-select v-if="conferenceServices && conferenceServices.data.length" class="hodo-select" v-model="selectedServiceIds" multiple placeholder="Chọn vé">
                    <el-option v-for="service in conferenceServices.data" :key="service.id" :label="service.name" :value="service.id" :disabled="handleDisabledService(service)">
                      <span style="float: left">{{ service.name }}</span>
                      <span style="float: right">{{ appUtils.formatMoney(service.price, 0) }} VNĐ</span>
                    </el-option>
                  </el-select>
                  <div class="text-sm">
                    <span>Tổng tiền:</span>
                    <span class="text-red-500 font-bold ml-2">{{ appUtils.formatMoney(totalPrice, 0) }} VNĐ</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-6 text-sm font-medium flex justify-end">
              <button :disabled="isLoading" @click="handleSubmit" class="flex items-center justify-center border-0 focus:outline-none mx-3 bg-blue-700 text-white rounded-md h-9 px-3 py-2">
                <div>{{ $t(isUpdate ? "Cập nhật" : "Thêm mới") }}</div>
              </button>
              <button :disabled="isLoading" @click="showModalEditGuest(false)" class="flex items-center justify-center border-0 focus:outline-none bg-blue-50 text-primary rounded-md h-9 px-3 py-2">
                <div>{{ $t("Hủy") }}</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { ButtonHoDo } from '@/components/Button'
import appUtils from '@/utils/appUtils'

export default {
  name: 'ModalEditGuest',
  components: { ButtonHoDo },
  props: {
    guestInfo: {
      type: Object,
      default: function () {
        return {
          name: '',
          email: '',
          job_title: '',
          workplace: '',
          phone: ''
        }
      }
    },
    isUpdate: Boolean,
    id: String
  },
  data () {
    const initForm = {
      name: '',
      email: '',
      job_title: '',
      workplace: '',
      phone: '',
      is_free: true
    }
    return {
      appUtils,
      isLoading: false,
      form: initForm,
      initForm,
      conferenceServices: null,
      selectedServiceIds: []
    }
  },
  watch: {
    guestInfo: {
      deep: true,
      handler () {
        this.handleMapData()
      }
    }
  },
  async created () {
    this.handleMapData()
    await this.getConferenceServices()
  },
  mounted () {
    const self = this
    this.$nextTick(function () {
      window.$(`#${this.id}`).on('hidden.bs.modal', function (e) {
        self.handleResetForm()
      })
    })
  },
  computed: {
    totalPrice () {
      const self = this
      if (!self.selectedServiceIds?.length) return 0
      return self.selectedServiceIds?.reduce((total, id) => {
        const service = self.conferenceServices?.data?.find(s => s.id === id)
        return total + (service?.price || 0)
      }, 0)
    }
  },
  methods: {
    async getConferenceServices () {
      try {
        const params = {
          conference_id: Number(this.$route.params.conf_id),
          page_num: 1,
          page_size: 1000,
          keyword: ''
        }
        const res = await this.$rf.getRequest('ConferenceRequest').getListConferenceServices(params)
        if (!res?.data) return
        this.conferenceServices = res.data
      } catch (error) {
        console.error(error)
      }
    },
    handleDisabledService (service) {
      const orgServiceTranIndex = this.guestInfo?.org_service_trans ? this.guestInfo?.org_service_trans?.findIndex(item => item?.conference_registrants_service_id === service?.id) : -1
      return Date.now() > service?.end_time || orgServiceTranIndex > -1
    },
    showModalEditGuest (show) {
      this.handleResetForm()
      window.$(`#${this.id}`).modal(show ? 'show' : 'hide')
    },
    handleMapData () {
      this.form = {
        ...this.form,
        name: this.guestInfo.name,
        email: this.guestInfo.email,
        job_title: this.guestInfo.job_title,
        workplace: this.guestInfo.workplace,
        phone: this.guestInfo.phone
      }
      this.selectedServiceIds = this.guestInfo?.org_service_trans?.map(s => s.conference_registrants_service_id)
    },
    async handleUpdateGuestInfo () {
      if (!this.guestInfo?.id) return

      try {
        this.isLoading = true
        const id = this.guestInfo.id

        const params = {
          job_title: this.form.job_title,
          workplace: this.form.workplace,
          phone: this.form.phone,
          name: this.form.name
        }

        await this.$rf.getRequest('ConferenceRequest').updateConferenceMemberInfo(id, params)

        this.showModalEditGuest(false)
        this.$emit('refresh')

        this.$message({
          type: 'success',
          message: this.$t('Cập nhật thông tin khách mời thành công')
        })
      } catch (error) {
        console.log(error)

        this.$message({
          type: 'error',
          message: this.$t('Có lỗi xảy ra khi cập nhật thông tin khách mời')
        })
      } finally {
        this.isLoading = false
      }
    },
    async handleCreateGuestInfo () {
      if (!this.$route.params.conf_id) return

      try {
        this.isLoading = true

        if (!this.selectedServiceIds?.length) {
          this.$message({
            type: 'error',
            message: this.$t('Vui lòng chọn vé')
          })
          return
        }

        if (!this.form.name.length || !this.form.email.length) {
          this.$message({
            type: 'error',
            message: this.$t('Vui lòng nhập tên và địa chỉ email')
          })
          return
        }

        const data = {
          ...this.form,
          conference_id: Number(this.$route.params.conf_id),
          registration_type: 1,
          conf_registrants_service_ids: this.selectedServiceIds
        }
        const res = await this.$rf.getRequest('ConferenceRequest').createConferenceMemberInfo(data)

        if (!res.data) return

        this.showModalEditGuest(false)
        this.$emit('refresh')

        this.$message({
          type: 'success',
          message: this.$t('Thêm mới khách mời thành công')
        })
      } catch (error) {
        console.log(error)

        this.$message({
          type: 'error',
          message: this.$t('Có lỗi xảy ra khi thêm mới khách mời')
        })
      } finally {
        this.isLoading = false
      }
    },
    handleSubmit () {
      if (this.guestInfo?.id) {
        this.handleUpdateGuestInfo()
      } else {
        this.handleCreateGuestInfo()
      }
    },
    handleResetForm () {
      this.form = { ...this.initForm }
      this.selectedServiceIds = []
    }
  }
}
</script>

<style scoped>
.cs-disabled {
  background-color: #cdcdcd74;
  color: #828282;
}
.hodo-select /deep/ .el-tag__close.el-icon-close {
  display: none;
}
</style>
